'use client'
import type { PropsWithChildren } from 'react'
import { Link, Popover, Text } from 'reshaped'
import { abbreviationText } from './styles.css'

export const Abbreviation = ({
  children,
  title,
}: PropsWithChildren<{ title?: string }>) => (
  <Popover triggerType="hover" padding={4}>
    <Popover.Trigger>
      {(attributes) => (
        <Link
          className={abbreviationText}
          color="inherit"
          variant="plain"
          attributes={attributes}
        >
          {children}
        </Link>
      )}
    </Popover.Trigger>
    <Popover.Content>
      <Text variant="body-3">{title}</Text>
    </Popover.Content>
  </Popover>
)
