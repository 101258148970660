'use client'
import { type PropsWithChildren, Suspense } from 'react'
import { Dismissible, Link, Modal, View, useToggle } from 'reshaped'
import { transparentBase } from '../styles/effects.css'

interface Props {
  label?: string
  title?: string
}
export function DisclosuresModal({
  children,
  label = 'Disclosures',
  title = 'Additional Disclosures',
}: PropsWithChildren<Props>) {
  const { active, activate, deactivate } = useToggle(false)
  return (
    <>
      <Link color="inherit" onClick={activate}>
        {label}
      </Link>
      <Modal
        size={{ m: '40vw' }}
        position={{ s: 'bottom' }}
        active={active}
        padding={0}
        onClose={deactivate}
      >
        <View maxHeight="95dvh">
          <View paddingInline={8} gap={3}>
            <View
              position="sticky"
              insetTop={0}
              paddingTop={6}
              paddingBottom={4}
              className={transparentBase}
              zIndex={5}
            >
              <Dismissible onClose={deactivate} closeAriaLabel="Close modal">
                <Modal.Title>{title}</Modal.Title>
              </Dismissible>
            </View>
            <Suspense>{children}</Suspense>
          </View>
        </View>
      </Modal>
    </>
  )
}
