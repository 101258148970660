'use client'
import type { PropsWithChildren } from 'react'
import { Link, Popover, Text } from 'reshaped'

interface Props {
  label: string
}
export const PopoverUIClient = ({ label, children }: PropsWithChildren<Props>) => (
  <Popover padding={8}>
    <Popover.Trigger>
      {(attributes) => (
        <Text color="neutral-faded">
          <Link color="inherit" attributes={attributes}>
            {label}
          </Link>
        </Text>
      )}
    </Popover.Trigger>
    <Popover.Content>{children}</Popover.Content>
  </Popover>
)
