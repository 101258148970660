'use client'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import clsx from 'clsx'
import { random } from 'radash'
import { type PropsWithChildren, useState } from 'react'
import { color, placeholder } from './placeholder.css'

export const Placeholder = ({ children = 'Placeholder' }: PropsWithChildren) => {
  const [colorState] = useState(random(0, 360))
  const styles = { [color]: colorState.toString() }
  return (
    <div style={assignInlineVars(styles)} className={clsx(placeholder)}>
      {children}
    </div>
  )
}
