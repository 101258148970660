'use client'
import type { PropsWithChildren } from 'react'
import { Reshaped } from 'reshaped'

export const ReshapedProvider = ({ children }: PropsWithChildren) => {
  return (
    <Reshaped theme="dynamic" defaultColorMode="light">
      {children}
    </Reshaped>
  )
}
