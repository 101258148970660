'use client'

import { useState } from 'react'
import { type ModalProps, Modal as ReshapedModal } from 'reshaped'
import { useEffectOnceWhen } from 'rooks'

// TODO: Remove this once reshaped has fixed the modal issue on server side
// https://github.com/formaat-design/reshaped/issues/293

export const Modal = (props: ModalProps) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffectOnceWhen(() => {
    setHasMounted(true)
  })

  return hasMounted ? <ReshapedModal {...props} /> : null
}

Modal.Title = ReshapedModal.Title
Modal.Subtitle = ReshapedModal.Subtitle
